<template>
    
    <div class="container">
        <h1>Contacts List</h1>
    </div>
    <br>

<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<p v-if="state == 'error'" class="orange">{{ error }}</p>
<div v-else-if="state == 'ready'">
    <div class="container" style="display: flex; justify-content: space-between;">
        <div class="row">
            <div v-for="user in users" :key="user.id">
                <div class="col-sm-12">
                    <div class="panel">
                        <div class="panel-body p-t-10">
                            <div class="media-main">
                                <div class="info">
                                    <h4>{{ user.name }}</h4>
                                    <p class="text-muted">{{ user.phone }}</p>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <ul class="social-links list-inline p-b-10">
        
                                <li class="list-inline-item">
                                <Popper>
                                    <a title="" data-placement="top" data-toggle="tooltip" href="#a" data-original-title="Website"><i class="fa fa-globe"></i></a>
                                        <template #content>
                                          <div>{{ user.website }}</div>
                                        </template>
                                </Popper>
                                </li>
                                <li class="list-inline-item">
                                <Popper>
                                    <a title="" data-placement="top" data-toggle="tooltip" href="#a" data-original-title="Compnay"><i class="fa fa-building"></i></a>
                                        <template #content>
                                          <div>{{ user.company.name }}</div>
                                        </template>
                                </Popper>
                                </li>
        
                                <li class="list-inline-item">
                                <Popper>
                                    <a title="" data-placement="top" data-toggle="tooltip" href="#a" data-original-title="Message"><i class="fa fa-envelope-o"></i></a>
                                        <template #content>
                                          <div>{{ user.email }}</div>
                                        </template>
                                </Popper>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br>
                <hr>
            </div>
        </div>
    </div>
</div>

<p v-if="state == 'loading'" class="orange">Loading contacts ....</p>
</template>
<script>
import Popper from "vue3-popper";
export default {
    name: 'ContactsList',
    data() {
        return {
            users: [],
            error: "",
            state: "loading",
        }
    },
    components: {
        Popper,
    },
    methods: {
        async getContacts() {
            try {
                const users = await fetch("https://jsonplaceholder.typicode.com/users");
                this.users = await users.json();
                this.state = "ready";
            } catch (err) {
                this.error = err;
                this.state = "error";
            }
        }
    },
    created() {
        this.getContacts();
    },
};


</script>

<style>
    body{
        margin-top:20px;
        background-color: #edf0f0;    
    }
    .btn-primary, 
    .btn-primary:hover, 
    .btn-primary:focus, 
    .btn-primary:active, 
    .btn-primary.active, 
    .btn-primary.focus, 
    .btn-primary:active, 
    .btn-primary:focus, 
    .btn-primary:hover, 
    .open>.dropdown-toggle.btn-primary {
        background-color: #3bc0c3;
        border: 1px solid #3bc0c3;
    }
    .p-t-10 {
        padding-top: 10px !important;
    }
    .media-main a.pull-left {
        width: 100px;
    }
    .thumb-lg {
        height: 84px;
        width: 84px;
    }
    .media-main .info {
        overflow: hidden;
        color: #000;
    }
    .media-main .info h4 {
        padding-top: 10px;
        margin-bottom: 5px;
    }
    .social-links li a {
        background: #EFF0F4;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        color: #7A7676;
    }
  :root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #dbd9d9;
    --popper-theme-text-color: #000000;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 16px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>